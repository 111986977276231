@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "blockHead";
  src:
    url("/public/fonts/BlockHead.woff2") format("woff2"),
    url("/public/fonts/BlockHead.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kufi";
  src:
    url("/public/fonts/kufi.woff2") format("woff2"),
    url("/public/fonts/kufi.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avant";
  src:
    url("/public/fonts/ITCAvantGardeStdBk.woff2") format("woff2"),
    url("/public/fonts/ITCAvantGardeStdBk.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src:
    url("/public/fonts/outfit.woff2") format("woff2"),
    url("/public/fonts/outfit.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cairo";
  src:
    url("/public/fonts/Cairo.ttf") format("woff2"),
    url("/public/fonts/Cairo.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #121212;
  overflow-x: hidden !important;
}

.unclickable-video {
  pointer-events: none;
}

* {
  -webkit-tap-highlight-color: transparent; /* For Webkit browsers (Safari, Chrome) */
  tap-highlight-color: transparent; /* For non-Webkit browsers */
}
.nav-logo {
  @apply w-[72.8px] h-[54px] md:w-[110px] md:h-[70px];
}

nav h1 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.custom-navbar-hover::before{
  content: " ";
  width: inherit;
  scale: 3.5 2.2;
  height: 10px;
  display: block;
  background: url('../public/nav-hover.png');
  background-size: 100% 100%;
  transform: translate(0px, 8px);
  opacity: 0;
  z-index: 100;
  transition: opacity 0.4s ease-in-out;
}

.custom-navbar-hover:hover::before{
  opacity: .75;
}

.slider-contnet{
  transform: translate(0px, 15px);
}

.landing-wrap {
  position: relative;
}

.landing-wrap::before {
  background-image: url("/public/hero-bg-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: " ";
  object-fit: contain;
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  box-shadow: 0 0 5em 5em #121212 inset;
}

.landing {
  position: relative;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: normal;
  text-transform: uppercase;
}

.glow{
  text-shadow: 0 0 7px #FFFFFFA9;
}

.glow.gold{
  text-shadow: 0 0 5px #BF9F4AA9;
}

.custom-bottom-border::after{
  transform: translateY(1rem);
  content: " ";
  height: 2px;
  width: 70%;
  margin: auto;
  background-color: #fff;
  display: block;
}

.custom-bottom-border.glow::after{
  box-shadow: 0 0 5px #FFFFFFA9;
}

.custom-top-border::before{
  transform: translateY(-1rem);
  content: " ";
  height: 2px;
  width: 70%;
  margin: auto;
  background-color: #fff;
  display: block;
}

.custom-top-border.glow::before{
  box-shadow: 0 0 5px #FFFFFFA9;
}

.hidePlayButton .react-player__shadow {
  display: none !important;
}

.backButton {
  position: absolute;
  top: 50%;
  background: #bf9f4a !important;
  z-index: 20;
  width: 3rem;
  padding: 1em;
  cursor: pointer;
}
@media (max-width: 768px) {
  .backButton {
    width: 1.6rem;
    left: -11%;
    top: 42%;
    padding: 0.5rem;
  }
  .landing-wrap::before {
    background-image: url("/public/hero-bg-4-mobile.jpg");
    background-size: cover;
    height: 120%;
    opacity: 0.35;
    box-shadow: 0 0 1.2em 1.2em #121212 inset;
  }
  
  .custom-navbar-hover::before{
    content: " ";
    width: inherit;
    scale: 3.5 2.2;
    height: 0px;
    display: block;
    background: url('../public/nav-hover.png');
    background-size: 100% 100%;
    transform: translate(0px, 8px);
    opacity: 0;
    z-index: 100;
    transition: opacity 0.4s ease-in-out;
    color: #bf9f4a
  }
}

.forwardButton {
  position: absolute;
  top: 50%;
  background: #bf9f4a;
  right: 0;
  z-index: 20;
  width: 3rem;
  padding: 1em;
  cursor: pointer;
}

@media (max-width: 768px) {
  .forwardButton {
    width: 1.6rem;
    right: -11%;
    top: 42%;
    padding: 0.5rem;
  }
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

#soonForwardButton {
  top: 30% !important;
  right: -15% !important;
}
#soonBackButton {
  top: 30% !important;
  left: -15% !important;
}

.soon-header{
  border-bottom: 0.1em solid #bf9f4a;
}